/** @jsx jsx */
import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { jsx, Container, Heading, Text } from "theme-ui"

import SEO from "../components/seo"
import styled from "@emotion/styled"

import { Header } from "../components/Header"
import { Footer } from "../components/Footer"

const IndexPage = ({ data }) => {
  const { us, us1 } = data
  const sources = [
    us.childImageSharp.fluid,
    { ...us1.childImageSharp.fluid, media: `(min-width: 40em)` },
  ]

  return (
    <>
      <SEO title="Tu Flete-Fletes CDMX" />
      <Header />
      <Container sx={{ variant: `styles.us` }}>
        <div sx={{ variant: `styles.us.title` }}>
          <Heading as="h1" variant="title">
            ¿Quiénes somos?
          </Heading>
          <Text as="p" variant="subtitle">
            Queremos que tus fletes dejen de ser un dolor de cabeza
          </Text>
        </div>
        <BackgroundImage
          Tag="div"
          fluid={sources}
          sx={{ variant: `styles.us.image` }}
        />
        <div sx={{ variant: `styles.us.copy` }}>
          <Text as="p">
            Somos un grupo de emprendedores especialistas en servicios de
            movilidad, nuestro principal interés es optimizar tu tiempo para que
            te ocupes de cumplir tus metas, mientras nosotros nos encargamos de
            transportar tu patrimonio.
          </Text>
          <Text as="p">
            {" "}
            Olvídate de la incertidumbre, nuestro equipo de profesionales son
            reconocidos por años de experiencia, ofreciendo un trato
            personalizado orientado al servicio.
          </Text>
          <Text as="p">
            En la actualidad es imprescindible contar con personas de confianza,
            con las que puedas estar tranquilo, nosotros somos esa opción, te
            invitamos a que contrates nuestros servicios y nos permitas ser
            colaboradores en tus éxitos personales y profesionales.
          </Text>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export const query = graphql`
  query {
    us: file(relativePath: { eq: "nosotros.png" }) {
      childImageSharp {
        fluid(maxWidth: 640) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    us1: file(relativePath: { eq: "nosotros.png" }) {
      childImageSharp {
        fluid(maxWidth: 850) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
